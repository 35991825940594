<app-ticket-modal 
    class="fixed left-0 top-[56px] w-full h-[calc(100%-76px)] z-10" 
    [url]="pdfUrl" 
    *ngIf="pdfUrl" 
    (close)="pdfUrl = undefined"
/>
<div class="mb-3">
    <div class="justify-between px-0 py-5 sm:flex sm:flex-col-reverse">
        <!-- MATCH -->
        <a [routerLink]="'/merkozes/' + fixtureWrapperObject.fixture.slug"
            *ngIf="isFixtureTypes(fixtureWrapperObject.fixture, fixtureTypes().MATCH)"
            class="flex justify-between select-none items-center text-white  sm:w-full min-w-[500px] max-w-[50%] sm:max-w-full sm:min-w-0">
            <img class="h-[40px]" [src]="fixtureWrapperObject.fixture.home_team_logo" [alt]="'Logó'" />
            <div class="px-2">
                <p class="text-[15px] font-black text-center">{{ fixtureWrapperObject.fixture.teams }}</p>
                <p class="text-sm text-center ">{{ fixtureWrapperObject.fixture.date }} ({{
                    fixtureWrapperObject.fixture.stadium}})</p>
            </div>
            <img class="h-[40px]" [src]="fixtureWrapperObject.fixture.away_team_logo" [alt]="'Logó'" />
        </a>
        <!-- OTHER EVENT -->
        <div *ngIf="isFixtureTypes(fixtureWrapperObject.fixture, fixtureTypes().OTHER)"
            class="flex gap-3 items-center text-white  sm:w-full min-w-[500px] max-w-[50%] sm:max-w-full sm:min-w-0">
            <img class="h-[40px]" *ngIf="fixtureWrapperObject.fixture.image" [src]="fixtureWrapperObject.fixture.image"
                [alt]="fixtureWrapperObject.fixture.name" />
            <div>
                <p class="text-[15px] font-black text-left">{{ fixtureWrapperObject.fixture.name}}</p>
                <p class="text-sm text-center ">{{ fixtureWrapperObject.fixture.date }} ({{
                    fixtureWrapperObject.fixture.stadium}})</p>
            </div>
        </div>
    </div>
    <ul class="flex gap-[20px] flex-col">
        <li class="flex flex-col p-5 relative bg-white rounded-[10px]"
            *ngFor="let ticket of fixtureWrapperObject.tickets">
            <!-- MOBILE -->
            <div class="hidden sm:block">
                <div class="flex justify-between items-center mb-[10px]">
                    <p class="text-sm font-bold">{{ ticket.title }}</p>

                    <!-- INVOICE ACTION -->
                    <span *ngIf="!isInvoiceIssued && !ticket.is_invoice_issued && !mapOnly"
                        class="text-[13px] text-[#a0a0a0] text-right">A számla kiállítása<br>folyamatban...</span>
                    <a target="_blank" *ngIf="(isInvoiceIssued || ticket.is_invoice_issued) && !mapOnly"
                        [href]="invoiceUrl(ticket)" class="text-[13px] text-[#a0a0a0] underline">Számla megtekintése</a>
                    <!-- INVOICE ACTION -->

                </div>
                <div class="justify-between flex">
                    <div class="max-w-[50%]">
                        <p class="text-[13px] text-[#a0a0a0]"
                            *ngIf="ticket.personal_details.array?.name || ticket.personal_details.array?.first_name">{{
                            ticket.personal_details.array?.name }} {{ ticket.personal_details.array?.first_name }}</p>
                        <p class="text-[13px] text-[#a0a0a0] -mt-[4px]"
                            *ngIf="ticket.personal_details.array?.birthdate">({{
                            ticket.personal_details.array?.birthdate }})</p>
                    </div>
                    <div *ngIf="false &&isCustomer()">
                        <img class="cursor-pointer max-w-[129px]" (click)="getAppleWalletPass(ticket.seat_id)"
                            src="/assets/images/add-to-apple-wallet.png" alt="Apple Wallet-hoz adás">
                    </div>
                </div>

                <!-- E TICKET -->
                <a *ngIf="!ticket.has_pending_transfer" 
                    [routerLink]="mobileTicketUrl(ticket)"
                    [queryParams]="queryString"
                    class="mt-[20px] w-full font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center">
                    <span *ngIf="!isETicketOpening(ticket.seat_id)">E-jegy megnyitása</span>
                    <div *ngIf="isETicketOpening(ticket.seat_id)" class="h-[19px]">
                        <app-spinner />
                    </div>
                </a>
                <!-- E TICKET -->

                <!-- TICKET DOWNLOAD -->
                <button *ngIf="ticket.is_document_generated && enableMobileTicketDownload" 
                    type="button"
                    class="w-full mt-2 font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center"
                    (click)="downloadTicket(ticket.seat_id, true)">
                    <span *ngIf="!isDownloading(ticket.seat_id)">Jegy letöltése</span>
                    <div *ngIf="isDownloading(ticket.seat_id)" class="h-[20px]">
                        <app-spinner />
                    </div>
                </button>
                <!-- TICKET DOWNLOAD -->

                <!-- CANCEL TRANSFER -->
                <span *ngIf="ticket.has_pending_transfer">
                    <p class="text-center text-sm text-[#a0a0a0] mt-2">A jegy átküldése folyamatban van: <strong>{{
                            ticket.pending_transfers.email }}</strong></p>
                    <div class="mt-4">
                        <app-button [isProcessing]="isRequesting.includes(ticket.uuid!)"
                            (click)="rekove(ticket)">Visszavonás</app-button>
                    </div>
                </span>
                <!-- CANCEL TRANSFER -->
            </div>
            <!-- DESKTOP -->
            <div class="flex justify-between sm:hidden">
                <div>
                    <p class="text-sm font-bold sm:mb-[10px] mb-[2px]">{{ ticket.title }} <span
                            *ngIf="isCashier()">(Vonalkód: {{ticket.identifier }})</span></p>
                    <p class="text-[13px] block sm:hidden mb-[2px]">{{ ticket.price }}</p>
                    <p class="text-[13px] block sm:hidden">{{ ticket.type }}</p>

                    <!-- INVOICE ACTION-->
                    <span *ngIf="!isInvoiceIssued && !ticket.is_invoice_issued"
                        class="text-[13px] text-gray-400 block sm:hidden mt-3">
                        A számla kiállítása folyamatban...
                    </span>
                    <a *ngIf="(isInvoiceIssued || ticket.is_invoice_issued)" target="_blank" [href]="invoiceUrl(ticket)"
                        class="text-[13px] block sm:hidden underline mt-3">Számla megtekintése</a>
                    <!-- INVOICE ACTION-->
                </div>
                <div class="items-center flex">
                    <p class="text-[13px]">{{ ticket.personal_details.short }}</p>
                </div>
                <div class="items-center flex">
                </div>
                <div class="flex justify-center items-center gap-3">
                    <!-- APPLE WALLET -->
                    <img class="cursor-pointer max-w-[155px]" *ngIf="false && isCustomer()"
                        (click)="getAppleWalletPass(ticket.seat_id)" src="/assets/images/add-to-apple-wallet.png"
                        alt="Apple Wallet-hoz adás">

                    <!-- TICKET DOWNLOAD -->
                    <button *ngIf="ticket.is_document_generated" type="button"
                        class="w-[200px] font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center"
                        (click)="downloadTicket(ticket.seat_id)">
                        <span *ngIf="!isDownloading(ticket.seat_id)">Jegy letöltése PDF-ben</span>
                        <div *ngIf="isDownloading(ticket.seat_id)" class="h-[20px]">
                            <app-spinner />
                        </div>
                    </button>

                    <!-- TICKET GENERATING -->
                    <span *ngIf="!ticket.is_document_generated" class="items-center px-[19px] flex flex-col gap-2">
                        <div>
                            <div class="pdf-loader"></div>
                            <span class="font-bold text-[13px] text-[var(--red)]">Jegy előkészítés alatt</span>
                        </div>
                    </span>

                    <!-- TICKET PRINT -->
                    <button type="button" *ngIf="isCashier()"
                        class="font-bold text-[13px] px-[19px] py-[10px] text-[#979797] border-[1px] rounded-[10px] border-[#979797] flex gap-[5px] justify-center items-center"
                        (click)="printTicket(ticket.seat_id)">
                        <span *ngIf="!isPrinting(ticket.seat_id)">Jegy nyomtatása</span>
                        <div *ngIf="isPrinting(ticket.seat_id)" class="h-[20px]">
                            <app-spinner />
                        </div>
                    </button>
                </div>
            </div>
        </li>
        <li *ngIf="showBackToMatch">
            <a [routerLink]="'/merkozes/' + fixtureWrapperObject.fixture.slug" class="text-white underline">Vissza a
                mérkőzéshez</a>
        </li>
    </ul>
</div>