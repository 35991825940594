<p *ngIf="error" class="text-[var(--red)] text-center mb-8 break-words" [innerHTML]="error"></p>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
    <div class="mb-5">
        <app-input name="email" [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''" placeholder="E-mail cím" (inputValueChangedEvent)="setValue('email', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'required')" errorMsg="Az e-mail cím megadása kötelező!"></app-input-error-msg>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'email')" errorMsg="Az e-mail cím formátuma nem megfelelő!"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input name="password" type="password" [customCssClasses]="isFieldInvalidAtAll('password') ? 'border border-[var(--red)]' : ''" placeholder="Jelszó" (inputValueChangedEvent)="setValue('password', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('password', 'required')" errorMsg="A jelszó megadása kötelező!"></app-input-error-msg>
    </div>
    <div >
        <app-button [isProcessing] = "isRequesting">Bejelentkezés</app-button>
    </div>
    <button (click)="showForgotPasswordForm()" class="text-[#888888] mt-5 mb-5 block underline text-[13px]">Elfelejtette jelszavát?</button>   
    <a (click)="showRegisterForm()" class="text-[#888888] cursor-pointer mt-5 mb-0 block text-[13px] text-center">Nincs még fiókod? <span class="text-[var(--red)]">Regisztrálj!</span></a>   
</form> 