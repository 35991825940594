import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Mask } from 'src/app/interfaces/Class/Misc/Mask';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input() placeholder: string = ""
  @Input() value: string = ""
  @Input() type: string = "text"
  @Input() customCssClasses: string = ""
  @Input() required: boolean = false
  @Input() mask?: Mask
  @Input() name: string = "input"
  
  @Output() inputValueChangedEvent: EventEmitter<string> = new EventEmitter<string>();

  public inputValueChangedFn(event: any)
  {
    this.value = event
    this.inputValueChangedEvent.emit(event);
  }
}
